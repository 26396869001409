import Flex from 'quarks/Flex';
import Link from 'quarks/Link';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

import type { FC } from 'react';

export interface BreadcrumbsProps extends BasicProps {
  breadcrumbs: {
    label: string | undefined;
    link: string;
  }[];
}

const Breadcrumbs: FC<BreadcrumbsProps> = ({ breadcrumbs, textColor }) => (
  <Flex
    flexDirection="row"
    data-cy="breadcrumb"
    flexWrap="nowrap"
    alignItems="center"
    alignSelf="flex-start"
    gap="4px"
    className="breadcrumbs"
    css={`
      flex-wrap: wrap;

      .breadcrumb-link {
        &:hover {
          cursor: pointer;
        }
      }
    `}
  >
    <Link
      href="/"
      textStyle="textXs"
      textDecoration="none"
      fontWeight="semiBold"
      textColor={textColor || 'gray-200'}
      paddingRight={12}
    >
      Home
    </Link>
    {breadcrumbs?.map((item, idx) => {
      if (!item?.label) {
        return null;
      }

      const isLastItem = idx === breadcrumbs.length - 1;
      const textColorValue = textColor || (isLastItem ? 'common-white' : 'gray-200');
      const fontWeightValue = isLastItem ? 'regular' : 'semiBold';
      const className = item?.onClick || item?.link ? 'breadcrumb-link' : '';

      return (
        <Link
          key={item?.label}
          href={item?.onClick ? '' : item?.link || ''}
          textStyle="textXs"
          textDecoration="none"
          onClick={item?.onClick}
        >
          <Flex flexDirection="row" alignItems="center">
            <Text paddingRight={12} fontWeight="semiBold" textColor={textColor || 'gray-400'}>
              /
            </Text>
            <Text paddingRight={12} textColor={textColorValue} fontWeight={fontWeightValue} className={className}>
              {item?.label}
            </Text>
          </Flex>
        </Link>
      );
    })}
  </Flex>
);

export default Breadcrumbs;
