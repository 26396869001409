/* eslint-disable react/prop-types */
import Slider from '@servicetitan/website-common/components/Slider';
import { PageProps, graphql, navigate } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import Cookies from 'js-cookie';
import Layout from 'layouts/Layout';
import { type FC, useEffect, useState } from 'react';

import Icon from 'atoms/Icons/Icon';
import color from 'atoms/colors/colors';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Image from 'quarks/Image';

import Dots from 'molecules/Pagination/Dots';

import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import ComponentButton from 'components/Button/ComponentButton';
import Form from 'components/Form';
import ConversionPanel from 'components/ResourceDetailLayout/components/ConversionPanel';
import VideoPopup from 'components/Video/VideoPopup';

import richTextParser from 'utils/richTextParser';

import type { ContentfulComponentForm, ContentfulEntityPerson, ContentfulTemplateWebinar } from 'graphqlTypes';

interface WebinarDataType {
  webinarData: ContentfulTemplateWebinar;
  form: ContentfulComponentForm;
}

const WebinarPage: FC<PageProps<WebinarDataType>> = ({ data: { webinarData, form }, location }) => {
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [onDemandRedirectUrl, setOnDemandRedirectUrl] = useState(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedSpeaker, setSelectedSpeaker] = useState<number>(0);
  const { body, date, dateRaw, title, speakers, video, eventId, eventKey } = webinarData;
  const isUpcoming = new Date(webinarData.date).getTime() > new Date().getTime();

  const breadcrumbs = [
    { label: 'Resources', link: '/resources' },
    { label: 'Webinars', link: '/webinar' },
  ];

  const formatDateTime = dt => {
    const options = {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'America/Los_Angeles',
    };

    return new Date(dt).toLocaleDateString(undefined, options);
  };

  const formatDate = dt => {
    const options = {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
    };

    return new Date(dt).toLocaleDateString(undefined, options);
  };

  const createRedirectUrl = (firstName, lastName, email) =>
    `https://event.on24.com/interface/registration/autoreg/index.html?eventid=${eventId}&sessionid=1&key=${eventKey}&firstname=${firstName}&lastname=${lastName}&email=${email}`;

  useEffect(() => {
    const submittedCookie = Cookies.get('resourceFormSubmitted');
    const firstName = Cookies.get('firstName');
    const lastName = Cookies.get('lastName');
    const email = Cookies.get('email');

    if (submittedCookie && firstName && lastName && email) {
      setOnDemandRedirectUrl(createRedirectUrl(firstName, lastName, email));
    }

    setFormSubmitted(!!submittedCookie);
  }, []);

  const speakerClick = (index: number) => {
    setSelectedSpeaker(index);
    const position = document.querySelector('#speaker-section');
    if (position) {
      window.scrollTo({
        top: position.getBoundingClientRect().top + window.scrollY - 125,
        behavior: 'smooth',
      });
    }
  };

  const handleFormSuccess = (values: any) => {
    const firstName = values?.fields?.find(({ name }) => name === 'firstname')?.value;
    const lastName = values?.fields?.find(({ name }) => name === 'lastname')?.value;
    const email = values?.fields?.find(({ name }) => name === 'email')?.value;

    Cookies.set('resourceFormSubmitted', 'true');
    Cookies.set('firstName', firstName);
    Cookies.set('lastName', lastName);
    Cookies.set('email', email);
    setFormSubmitted(true);
    const redirectUrl = createRedirectUrl(firstName, lastName, email);
    setOnDemandRedirectUrl(redirectUrl);
    navigate(redirectUrl);
  };

  const FormContainer = () =>
    formSubmitted && !isUpcoming ? (
      <Flex
        width="100%"
        height="fit-content"
        flexDirection="row"
        overflowX="hidden"
        overflowY="hidden"
        border="1px solid"
        borderColor="gray-200"
        hover={{ boxShadow: 'xxl' }}
        transition="200ms ease-in-out"
        backgroundColor="common-white"
        paddingY={48}
        paddingX={18}
        borderRadius="4px"
        boxShadow="xl"
        gap="12px"
        alignItems="center"
        lg={{ position: 'sticky', top: '120px' }}
      >
        <Flex width="100%" justifyContent="left" flexDirection="column" gap="20px">
          <h3 className="font-bold text-gray-900 text-lg">Enjoy!</h3>
          {(video?.videoUrl || onDemandRedirectUrl) && (
            <ComponentButton
              label="Watch Now"
              handleClick={() => (onDemandRedirectUrl ? navigate(onDemandRedirectUrl) : setModalOpen(true))}
              href={undefined}
              className="!w-full !max-w-full"
            />
          )}
        </Flex>
      </Flex>
    ) : (
      <Flex
        css={`
          .form-container {
            position: sticky;
            top: 120px;
          }
        `}
      >
        <Form
          height="fit-content"
          {...form}
          onSuccess={handleFormSuccess}
          {...(isUpcoming ? { heading: 'Register to Watch' } : {})}
        />
      </Flex>
    );

  return (
    <Layout slug={location.pathname} seo={webinarData.seo}>
      <Flex
        position="relative"
        flexDirection="column"
        xs={{ paddingTop: 12 }}
        sm={{ paddingTop: 48 }}
        backgroundColor="common-white"
        css={`
          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 20%;
            background-color: ${color.common.white};
          }
        `}
      >
        <Flex
          width="100%"
          maxWidth="1320px"
          marginX="auto"
          paddingX={20}
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
          gap="56px"
          position="relative"
          zIndex="1"
        >
          <Breadcrumbs breadcrumbs={breadcrumbs} textColor="common-black" />
          <Flex
            width="100%"
            gap="40px"
            flexWrap="wrap"
            flexDirection="column-reverse"
            md={{ flexDirection: 'row', flexWrap: 'nowrap' }}
          >
            <Flex width="100%" md={{ width: '65%' }} gap="20px" flexDirection="column">
              <Heading
                as="h1"
                fontSize="textSm"
                lineHeight="h4"
                css={`
                  color: ${color.green[300]};
                `}
              >
                {isUpcoming ? 'UPCOMING' : 'ON DEMAND'} WEBINAR
              </Heading>
              {title && (
                <Heading as="h1" fontSize="h1" lineHeight="h1" textColor="common-black" fontFamily="secondaryFont">
                  {title}
                </Heading>
              )}
              {date && (
                <Flex alignItems="center" gap="8px" marginBottom={32}>
                  <Icon id="headphones-02" size={25} />
                  <Heading as="h1" marginBottom={0} fontSize="textSm" textColor="gray-900" textTransform="uppercase">
                    {isUpcoming ? `${formatDateTime(dateRaw)} (PST)` : formatDate(dateRaw)}
                  </Heading>
                </Flex>
              )}
              <Flex width="100%" md={{ display: 'none' }}>
                <FormContainer />
              </Flex>
              {speakers && (
                <>
                  <Flex
                    fontSize="textLg"
                    lineHeight="h5"
                    textColor="gray-900"
                    fontWeight="semiBold"
                    fontFamily="secondaryFont"
                    flexDirection="column"
                    css={`
                      font-size: 24px;
                    `}
                    width="100%"
                    gap="12px"
                  >
                    Guest Speakers
                    <Flex
                      flexDirection="row"
                      width="100%"
                      maxWidth="100%"
                      gap="40px"
                      flexWrap="wrap"
                      md={{ flexWrap: 'nowrap' }}
                      position="relative"
                    >
                      {speakers?.map((speaker, index) => (
                        <Flex
                          key={speaker?.id}
                          width="100%"
                          md={{
                            maxWidth: '50%',
                            width: `${100 / speakers.length}%`,
                          }}
                        >
                          <Flex
                            width="100%"
                            height="100%"
                            flexDirection="row"
                            overflowX="hidden"
                            overflowY="hidden"
                            border="1px solid"
                            borderColor="gray-200"
                            hover={{ boxShadow: 'xxl' }}
                            transition="200ms ease-in-out"
                            backgroundColor="common-white"
                            paddingY={20}
                            paddingX={18}
                            borderRadius="4px"
                            boxShadow="xl"
                            gap="12px"
                            alignItems="center"
                          >
                            <Flex width="30%" height="80px">
                              <Image borderRadius="50%" image={{ file: { url: speaker?.headshot?.file?.url } }} />
                            </Flex>
                            <Flex width="70%" flexDirection="column">
                              <Flex fontSize="textSm" fontWeight="bold">
                                {speaker?.name}
                              </Flex>
                              <Flex fontSize="textSm" fontWeight="regular">
                                {speaker?.position}
                              </Flex>
                              <Flex
                                className="cursor-pointer"
                                fontSize="textSm"
                                fontWeight="bold"
                                onClick={() => speakerClick(index)}
                              >
                                Read Bio
                              </Flex>
                            </Flex>
                          </Flex>
                        </Flex>
                      ))}
                    </Flex>
                  </Flex>
                </>
              )}
              {body && (
                <Flex width="100%" textColor="gray-900" marginTop={40} flexDirection="column">
                  {richTextParser(body)}
                </Flex>
              )}
            </Flex>
            <Flex width="100%" display="none" md={{ display: 'flex', width: '35%' }}>
              <FormContainer />
            </Flex>
          </Flex>
          <Flex
            width="100%"
            md={{
              width: '85%',
            }}
            xl={{
              width: '90%',
            }}
            xxl={{
              width: '100%',
            }}
            flexWrap="wrap"
            css={`
              #speaker-section .max-h-fit {
                background-color: rgb(17, 12, 57);
                border-radius: 0 0 4px 4px;
                align-items: center;
                height: 100%;
                max-height: 100%;

                @media (max-width: 767px) {
                  align-items: flex-start;
                }
              }
            `}
          >
            {speakers && (
              <Slider<ContentfulEntityPerson>
                id="speaker-section"
                items={speakers}
                active={selectedSpeaker}
                onItemRender={(item: ContentfulEntityPerson) => (
                  <Flex
                    width="100%"
                    paddingX={48}
                    className="rounded-b-[4px]"
                    paddingBottom={40}
                    paddingTop={0}
                    fontStyle="textLg"
                    textColor="common-white"
                    height="fit-content"
                    backgroundColor="navy-900"
                  >
                    <Flex
                      width="100%"
                      gap="50px"
                      flexDirection="column"
                      md={{ flexDirection: 'row' }}
                      height="fit-content"
                      alignItems="center"
                    >
                      <Flex
                        borderRadius="50%"
                        height="200px"
                        justifyContent="center"
                        css={`
                          height: 265px;
                          width: 265px;
                          flex-basis: 265px;
                          flex-grow: 0;
                          flex-shrink: 0;
                          overflow: hidden;
                        `}
                      >
                        <Image
                          width="100%"
                          height="100%"
                          objectFit="cover"
                          image={{ file: { url: item?.headshot?.file?.url } }}
                        />
                      </Flex>
                      <Flex flexDirection="column" gap="15px" textAlign="center" md={{ textAlign: 'left' }}>
                        <Container>
                          <Container fontStyle="textSm" fontWeight="bold">
                            {item?.name}
                          </Container>
                          {item?.position}
                          {item?.company && ` , ${item?.company?.company}`}
                        </Container>
                        <Flex fontStyle="textSm">{item.bio && renderRichText(item.bio)}</Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                )}
                Header={() => (
                  <Flex
                    fontFamily="secondaryFont"
                    className="rounded-t-[4px]"
                    paddingX={48}
                    paddingY={40}
                    textColor="common-white"
                    fontWeight="semiBold"
                    css={{ lineHeight: '38px', fontSize: '30px' }}
                    backgroundColor="navy-900"
                    width="100%"
                    justifyContent="center"
                    md={{ justifyContent: 'flex-start' }}
                  >
                    Meet your speaker
                  </Flex>
                )}
                LeftIcon={{
                  Icon: () => <Icon id="chevron-left" fill={color.navy[900]} size={80} />,
                  ContainerClassName: 'md:left-[-5.2rem]',
                }}
                RightIcon={{
                  Icon: () => <Icon id="chevron-right" fill={color.navy[900]} size={80} />,
                  ContainerClassName: 'md:right-[-5.2rem]',
                }}
                PagingIcon={({ isActive, ...props }) => <Dots color={isActive ? 'gray-900' : 'navy-50'} {...props} />}
              />
            )}
          </Flex>
        </Flex>
        <ConversionPanel paddingTopDesktop={48} id="conversion-panel-webinar" />
      </Flex>
      <VideoPopup open={modalOpen} onClose={() => setModalOpen(false)} videoUrl={video?.videoUrl} />
    </Layout>
  );
};

export default WebinarPage;

export const pageQuery = graphql`
  query templateWebinarQuery($slug: String!) {
    webinarData: contentfulTemplateWebinar(slug: { eq: $slug }) {
      ...contentfulTemplateWebinar
    }
    form: contentfulComponentForm(contentful_id: { eq: "CUQbP180HKU0tlHaR4NWk" }) {
      ...contentfulComponentForm
    }
  }
`;
